import {
    createColumnHelper,
    getCoreRowModel,
    useReactTable,
} from "@tanstack/react-table";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { identityConfig } from "../../auth/authConst";
import assets from "../../common/assets";
import colors from "../../common/colors";
import { translatePaymentCode } from "../../common/helpers/convertPaymentMethod";
import { isExpired } from "../../common/helpers/dateHelpers";
import { ListOption } from "../../common/types";
import { space } from "../../common/variables";
import Button from "../../components/Button";
import Container from "../../components/Container";
import DropdownFilter from "../../components/DropdownFilter";
import EmptyState from "../../components/EmptyState";
import Loader from "../../components/Loader";
import Plate from "../../components/Plate";
import SubHeader from "../../components/SubHeader";
import Table, { PaginationStyles } from "../../components/Table/Table";

import { CustomerBillingDTO } from "../../api/apiTypes";
import { appConfig } from "../../appConfig";
import { TableRowStyles } from "../../components/Table/TableRow";
import Tag from "../../components/Tag";
import { CustomerFilterContext } from "../../contexts/CustomerFilterContext";
import { UserContext } from "../../contexts/UserContext";
import { useAllUnitsQuery } from "../../queries/units/useAllUnitsQuery";
import { useAllCustomersQuery } from "../../queries/useAllCustomersQuery";
import { mapTruthyValuesToQueryStringParams } from "../../utils/urlUtils";
import UnitTimeFramesListing from "../UnitView/UnitTimeFramesListing";
import CustomerSearch from "./CustomerSearch";
import {
    BlockedShoppingIcon,
    BlockedShoppingWrapper,
    getOrderStatus,
    mapCustomerFromDTO,
    validateHandlaProxyButton,
} from "./customerDetailsConfig";
import { CustomerListingItem } from "./customerTypes";

const HANDLA_REDIRECT_URL = appConfig.HANDLA_REDIRECT_URL;
const columnHelper = createColumnHelper<CustomerListingItem>();

const PAGINATION_SIZE = 50;

const CustomerListingView = () => {
    const navigate = useNavigate();
    const { pathname, search } = useLocation();

    const [filterExpanded, setFilterExpanded] = useState(false);
    const { authUserData } = useContext(UserContext);
    const { customerFilter: unitFilter, setCustomerFilter: setUnitFilter } =
        useContext(CustomerFilterContext);

    const searchParams = new URLSearchParams(search);
    const currentPageFromUrl = searchParams.get("page");
    const currentPage =
        (currentPageFromUrl &&
            (parseInt(currentPageFromUrl) >= 1
                ? parseInt(currentPageFromUrl)
                : 1)) ||
        1;

    const searchQuery = searchParams.get("query") || "";
    const updateQueryString = (
        values: Record<string, string | number | undefined>
    ) => {
        const params = mapTruthyValuesToQueryStringParams(search, values);

        navigate({
            pathname,
            search: params.toString(),
        });
    };

    const updatePageQueryString = (value: number) => {
        updateQueryString({ page: value <= 1 ? undefined : value });
    };

    const updateSearchQueryString = (value: string) => {
        updateQueryString({
            query: value,
            page: undefined,
        });
    };

    const redirectToHandla = useCallback(
        (customerId?: string, unitId?: string) => {
            const session = sessionStorage.getItem(
                `oidc.user:${identityConfig.authority}:${identityConfig.client_id}`
            );

            if (!session) {
                return;
            }

            const userId = JSON.parse(session)["profile"]["sub"];
            const municipalictyCode = authUserData?.municipalityCode;

            window.location.href = `${HANDLA_REDIRECT_URL}/redirect/municipal/proxyLogin/${municipalictyCode}/${unitId}/${userId}/${customerId}`;
        },
        [authUserData]
    );

    const paginationFromUrl = useMemo(
        () => ({
            pageIndex: currentPage,
            pageSize: PAGINATION_SIZE,
        }),
        [currentPage]
    );

    const columns = useMemo(
        () => [
            columnHelper.display({
                id: "actions",
                header: () => "",
                enableSorting: false,
                cell: (props) => (
                    <TableRowStyles.Cell rowLength={6} fixedWidth={120}>
                        <TableRowStyles.Title>
                            <Button
                                size="small"
                                preventDefault
                                onClick={() =>
                                    redirectToHandla(
                                        props.row.original.data.uid,
                                        props.row.original.data
                                            .municipalityUnitCode
                                    )
                                }
                                title="Handla"
                                disabled={
                                    !validateHandlaProxyButton(
                                        mapCustomerFromDTO({
                                            billingAddress:
                                                props.row.original
                                                    ?.billingAddress,
                                            customer: props.row.original?.data,
                                            restrictions:
                                                props.row.original
                                                    ?.restrictions,
                                        }),
                                        props.row.original.data
                                    ).isValid
                                }
                            />
                        </TableRowStyles.Title>
                    </TableRowStyles.Cell>
                ),
                meta: {
                    getCellContext: () => ({
                        length: 6,
                        fixedWidth: 100,
                    }),
                },
            }),
            columnHelper.accessor(
                (row) => row.data.firstName + " " + row.data.lastName,
                {
                    id: "name",
                    header: () => "Namn",
                    enableSorting: false,
                    cell: (props) => props.getValue(),
                    meta: {
                        getCellContext: () => ({
                            length: 6,
                            growWidth: true,
                        }),
                    },
                }
            ),
            columnHelper.accessor((row) => row.data.municipalityUnitName, {
                id: "unit",
                header: () => "Enhet",
                enableSorting: false,
                cell: (props) => props.getValue(),
                meta: {
                    getCellContext: () => ({
                        length: 6,
                        growWidth: true,
                    }),
                },
            }),
            columnHelper.accessor((row) => row.data.timeWindowRestrictions, {
                id: "timeWindowRestrictions",
                header: () => "Leveranstid",
                enableSorting: false,
                cell: (props) => (
                    <UnitTimeFramesListing
                        minified
                        restrictions={props.getValue()}
                        hasNotifications={props
                            .getValue()
                            ?.some((item) => !!item.replacementHint)}
                    />
                ),
                meta: {
                    getCellContext: () => ({
                        length: 6,
                        growWidth: true,
                    }),
                },
            }),
            columnHelper.accessor((row) => row.data.paymentInfo, {
                id: "payment",
                header: () => "Betalning",
                enableSorting: false,
                cell: (props) => {
                    const paymentInfo = props.getValue();
                    if (!paymentInfo) return "";
                    if (paymentInfo.paymentToken)
                        return isExpired(
                            paymentInfo.paymentToken.expiryDate
                        ) ? (
                            <Tag theme="red-light">
                                <strong>Kort utgått</strong>
                            </Tag>
                        ) : (
                            translatePaymentCode(paymentInfo.code)
                        );
                    return translatePaymentCode(paymentInfo.code);
                },
                meta: {
                    getCellContext: () => ({
                        length: 6,
                        growWidth: true,
                    }),
                },
            }),
            columnHelper.accessor(
                (row) => getOrderStatus(row.orders)?.sortPredicate,
                {
                    id: "orders",
                    header: () => "Order",
                    enableSorting: false,
                    cell: (props) => (
                        <>
                            {props.row.original.data
                                .blockedForMunicipalityShopping &&
                            props.row.original.data
                                .blockedForMunicipalityShoppingReason ? (
                                <BlockedShoppingWrapper>
                                    <BlockedShoppingIcon>
                                        {assets.exclamation}
                                    </BlockedShoppingIcon>
                                    <div>
                                        Vi har låst möjligheten för slutkunden
                                        att handla
                                    </div>
                                </BlockedShoppingWrapper>
                            ) : (
                                getOrderStatus(props.row.original.orders)
                                    ?.element || null
                            )}
                        </>
                    ),
                    meta: {
                        getCellContext: () => ({
                            length: 6,
                            growWidth: true,
                        }),
                    },
                }
            ),
        ],
        [redirectToHandla]
    );

    const customersQuery = useAllCustomersQuery(
        paginationFromUrl.pageIndex - 1,
        paginationFromUrl.pageSize,
        unitFilter?.map(({ id }) => id),
        searchQuery
    );

    const allUnitsQuery = useAllUnitsQuery();

    const customerListingItems = useMemo(
        () =>
            customersQuery.data?.userList?.map((item) => {
                return {
                    data: {
                        ...item,
                        municipalityUnitName:
                            allUnitsQuery.data?.find(
                                (unit) =>
                                    unit.code === item.municipalityUnitCode
                            )?.name || "Missing unit name",
                    },
                    orders: item.latestOrders || [],
                    restrictions: item.timeWindowRestrictions || [],
                    billingAddress:
                        item.billingAddress || ({} as CustomerBillingDTO),
                    replacements: [],
                };
            }, [] as CustomerListingItem[]),
        [allUnitsQuery.data, customersQuery.data]
    );

    const setFilterChoice = (selectedFilter: ListOption) => {
        const alreadySelected = unitFilter.find(
            (filter) => filter.id === selectedFilter.id
        );

        if (alreadySelected) {
            const updatedFilters = [...unitFilter].filter(
                (filter) => filter.id !== selectedFilter.id
            );
            setUnitFilter(updatedFilters);
        } else {
            setUnitFilter([...unitFilter, selectedFilter]);
        }
    };

    const tablePagination = useMemo(
        () => ({
            pageIndex: paginationFromUrl.pageIndex - 1, // react-table uses 0-based index and paginationFromUrl uses 1-based index since it is held in the url
            pageSize: paginationFromUrl.pageSize,
        }),
        [paginationFromUrl.pageIndex, paginationFromUrl.pageSize]
    );

    const table = useReactTable({
        data: customerListingItems || [],
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        pageCount: customersQuery.data?.pagination?.totalPages ?? -1,
        manualPagination: true,
        onPaginationChange: (updater) => {
            if (typeof updater === "function") {
                const newIndex = updater(tablePagination).pageIndex;
                updatePageQueryString(newIndex + 1);
            }
        },
        state: {
            pagination: tablePagination,
        },
    });

    return (
        <Container>
            <CustomerSearch
                key={searchQuery}
                initialValue={searchQuery}
                onEnterPress={(value) => {
                    updateSearchQueryString(value);
                }}
            />
            <SubHeader
                title="Slutkunder"
                leftContent={
                    allUnitsQuery.isSuccess &&
                    !!allUnitsQuery.data.length && (
                        <DropdownFilter
                            data={allUnitsQuery.data.map((unit) => ({
                                id: unit.code,
                                name: unit.name,
                            }))}
                            selectedData={unitFilter}
                            clearFilter={() => setUnitFilter([])}
                            expandedCallback={(expanded) =>
                                setFilterExpanded(expanded)
                            }
                            placeholder="Alla enheter"
                            itemName="Enheter"
                            setChoice={setFilterChoice}
                        />
                    )
                }
                rightContent={
                    <RightContent>
                        <PdfLink
                            href="resources/files/coop_autogiroanmalan.pdf"
                            download>
                            Ladda ner Autogiroanmälan (pdf)
                        </PdfLink>
                        {(authUserData?.municipalityRole === "ADMIN" ||
                            (authUserData?.municipalityRole === "ORDERER" &&
                                authUserData?.customerCreationAllowed &&
                                !!authUserData?.authorisedUnitCodes
                                    ?.length)) && (
                            <Button
                                size="small"
                                onClick={() => {
                                    navigate(pathname + "/skapa-ny");
                                }}
                                title="Skapa ny"
                            />
                        )}
                    </RightContent>
                }
            />
            <Plate
                spaceBottom={true}
                faded={filterExpanded || customersQuery.isFetching}
                overScroll>
                {customersQuery.isLoading ? (
                    <Loader />
                ) : !customerListingItems?.length ? (
                    <EmptyState text="Det finns inga slutkunder." />
                ) : (
                    <Table
                        table={table}
                        getLinkTo={(data) => pathname + "/" + data.data.uid}
                    />
                )}
            </Plate>
            {!customersQuery.isLoading && table.getPageCount() > 1 && (
                <PaginationStyles.Wrapper>
                    <PaginationStyles.LeftTrigger
                        onClick={() => {
                            table.previousPage();
                        }}
                        disabled={!table.getCanPreviousPage()}>
                        {assets.angle}
                    </PaginationStyles.LeftTrigger>

                    <PaginationStyles.Content>
                        Sida {table.getState().pagination.pageIndex + 1} av{" "}
                        {table.getPageCount()}
                    </PaginationStyles.Content>

                    <PaginationStyles.RightTrigger
                        onClick={() => {
                            table.nextPage();
                        }}
                        disabled={!table.getCanNextPage()}>
                        {assets.angleRight}
                    </PaginationStyles.RightTrigger>
                </PaginationStyles.Wrapper>
            )}
        </Container>
    );
};

const RightContent = styled.div`
    display: flex;
    align-items: center;
`;

const PdfLink = styled.a`
    margin-right: ${space.xLarge};
    color: ${colors.green};
`;

export default CustomerListingView;
