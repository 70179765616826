import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { customerMethods } from "../../api/apiMethods";
import { genericErrorConfig } from "../../common/exceptions";
import { MatkontoTmpInfoProvider } from "../../common/helpers/matkontoTmpInfoProider";
import Container from "../../components/Container";
import Loader from "../../components/Loader";
import { ModalContext } from "../../components/Modal";
import Plate from "../../components/Plate";
import SubHeader from "../../components/SubHeader";
import { UserContext } from "../../contexts/UserContext";
import { Logger } from "../../utils/logger/Logger";

export const matkontoCallbackPath = "/callback/matkonto/return";

const MatkontoCallbackView = () => {
    const [transactionCode, setTransactionCode] = useState<string>("");
    const location = useLocation();

    useEffect(() => {
        if (location.search) {
            let params = new URLSearchParams(location.search);
            const code = params.get("code");
            if (code) setTransactionCode(code);
        }
    }, [location]);

    const { authUserData } = useContext(UserContext);
    const { setModalOptions } = useContext(ModalContext);

    useEffect(() => {
        const matkontoVerification = async () => {
            if (!authUserData || !transactionCode) return;

            const verifyCustomerInfo = MatkontoTmpInfoProvider.get();
            if (!verifyCustomerInfo) return;

            try {
                const response = await customerMethods(
                    authUserData.municipalityCode
                ).verifyMatkonto(
                    verifyCustomerInfo.unitId,
                    verifyCustomerInfo.customerId,
                    transactionCode
                );

                if (response) {
                    window.location.href = `/slutkund/${verifyCustomerInfo.customerId}`;
                }
            } catch (error: any) {
                Logger.error(error);
                setModalOptions(
                    genericErrorConfig(
                        () => setModalOptions({ visible: false }),
                        "Kunde inte verify Matkonto."
                    )
                );
            }
        };

        matkontoVerification();
    }, [transactionCode, authUserData, setModalOptions]);

    return (
        <Container>
            <SubHeader title="Matkonto verification" />
            <Plate>
                <Loader />
            </Plate>
        </Container>
    );
};

export default MatkontoCallbackView;
