import isEqual from "lodash/isEqual";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import assets from "../../common/assets";
import { genericErrorConfig } from "../../common/exceptions";
import { TimeRestriction } from "../../common/types";
import { space } from "../../common/variables";
import { Alert } from "../../components/Alert";
import Button from "../../components/Button";
import Container from "../../components/Container";
import Cell, { CellHeading } from "../../components/Grid/Cell";
import CellBlock, {
    CellBlockFooter,
    CellBlockSection,
    CellBlockTitle,
    CellBlockValue,
} from "../../components/Grid/CellBlock";
import Grid from "../../components/Grid/Grid";
import Loader from "../../components/Loader";
import { ModalContext } from "../../components/Modal";
import Plate from "../../components/Plate";
import {
    ReplacedTimeWindowLabel,
    ReplacementTimeWindow,
    ReplacementTimeWindowLabel,
} from "../../components/ReplacementTimeWindow";
import SubHeader from "../../components/SubHeader";
import TimeFrameList from "../../components/TimeFrameList";
import TriggerFormInitially from "../../components/TriggerFormInitially";
import { useUpdateUnitMutation } from "../../mutations/useUpdateUnitMutation";
import { useUnitByIdQuery } from "../../queries/units/useUnitByIdQuery";
import { useUnitReplacementWindowsQuery } from "../../queries/units/useUnitReplacementWindowsQuery";
import { useUnitRestrictionsQuery } from "../../queries/units/useUnitRestrictionsQuery";
import { useMunicipalityRestrictionsQuery } from "../../queries/useMunicipalityRestrictionsQuery";
import { Logger } from "../../utils/logger/Logger";
import UnitInfoForm from "./UnitInfoForm";
import UnitInfoListing from "./UnitInfoListing";
import UnitTimeFramesListing from "./UnitTimeFramesListing";
import { useSelectedTimeFrames, useUnitInfoForm } from "./UnitView.hooks";
import {
    convertMunicipalityRestrictionsFromOutput,
    convertUnitForInput,
    convertUnitFromOutput,
} from "./unitDetailsConfig";
import { UnitInput } from "./unitTypes";

interface UnitFormsEditing {
    information?: boolean;
    timeFrames?: boolean;
}

const UnitDetailsView = () => {
    const navigate = useNavigate();
    const { setModalOptions } = useContext(ModalContext);

    const [isEditing, setIsEditing] = useState<UnitFormsEditing | undefined>({
        information: false,
        timeFrames: false,
    });

    const unitInfoForm = useUnitInfoForm();

    const [
        timeframesChangedMsgAlreadyShown,
        setTimeframesChangedMsgAlreadyShown,
    ] = useState(false);

    const showTimeframesChangeMsg = () => {
        setModalOptions({
            title: "OBS!",
            subtitle:
                "Tar du bort ett tidsfönster för en enhet kan befintliga slutkunder som har det valt sedan tidigare fortsätta använda det tills att det korrigerats. Tidsfönster som tas bort för enheten kommer dock inte kunna användas för slutkunder framöver.",
            visible: true,
            lockDismiss: true,
            buttons: [
                <Button
                    size="small"
                    title="Okej, då vet jag"
                    onClick={() => {
                        setModalOptions({ visible: false });
                    }}
                />,
            ],
        });
    };

    const {
        selectedTimeFrames,
        setSelectedTimeFrames,
        updateSelectedTimeframes,
    } = useSelectedTimeFrames({
        onDeselect: () => {
            if (!timeframesChangedMsgAlreadyShown) {
                setTimeframesChangedMsgAlreadyShown(true);
                showTimeframesChangeMsg();
            }
        },
    });

    const { unitId } = useParams<{ unitId?: string }>();

    const { isLoading: unitByIdLoading, data: unitByIdData } =
        useUnitByIdQuery(unitId);

    const restrictionsQuery = useUnitRestrictionsQuery(unitId);

    const restrictionsData = useMemo(
        () => restrictionsQuery.data || [],
        [restrictionsQuery.data]
    );

    const unitData = useMemo(
        () => convertUnitFromOutput(unitByIdData || undefined),
        [unitByIdData]
    );

    // Sync unit data into a form when API call returns
    useEffect(() => {
        if (unitData) {
            unitInfoForm.reset(unitData);
        }
    }, [unitInfoForm, unitData]);

    useEffect(() => {
        setSelectedTimeFrames(restrictionsData);
    }, [restrictionsData, setSelectedTimeFrames]);

    const { data: municipalityRestrictionsData } =
        useMunicipalityRestrictionsQuery();

    const availableTimeFrames = useMemo(
        () =>
            municipalityRestrictionsData
                ? convertMunicipalityRestrictionsFromOutput(
                      municipalityRestrictionsData
                  )
                : [],
        [municipalityRestrictionsData]
    );

    const { data: unitReplacementWindowsData } =
        useUnitReplacementWindowsQuery(unitId);

    const { isPending: updateUnitIsLoading, mutate: updateUnitMutate } =
        useUpdateUnitMutation({
            onSuccess: () => {
                setIsEditing(undefined);
            },
            onError: (error) => {
                Logger.error(error);
                setModalOptions(
                    genericErrorConfig(() => {
                        setModalOptions({ visible: false });
                        navigate(-1);
                    }, "Kunde inte spara uppgifterna.")
                );
            },
        });

    const saveUnitDetails = (
        input: UnitInput,
        inputTimeFrames: TimeRestriction[]
    ) => {
        updateUnitMutate({
            input: input,
            unitId,
            selectedTimeFrames: inputTimeFrames,
        });
    };

    const sortTimeFrames = (data: TimeRestriction[]) => {
        return data
            .map((item, i) => ({
                ...item,
                index: i,
            }))
            .sort((a, b) => {
                const orderA = `${a.dayOfWeek} ${a.startTime}-${a.endTime}`;
                const orderB = `${b.dayOfWeek} ${b.startTime}-${b.endTime}`;

                if (orderA < orderB) {
                    return -1;
                }
                if (orderB < orderA) {
                    return 1;
                }
                return 0;
            })
            .map((item) => data[item.index]);
    };

    const showUnsavedChangesModal = (action: () => void) => {
        setModalOptions({
            visible: true,
            title: "Osparade ändringar",
            subtitle:
                "Du har osparade ändringar i den nuvarande sektionen. Vill du ändå byta?",
            buttons: [
                <Button
                    size="small"
                    title="Fortsätt"
                    onClick={() => {
                        action();
                        setModalOptions({ visible: false });
                    }}
                />,
                <Button
                    size="small"
                    title="Avbryt"
                    theme="inverted"
                    onClick={() => setModalOptions({ visible: false })}
                />,
            ],
        });
    };

    const resetForm = () => {
        unitInfoForm.reset(unitData);
        setSelectedTimeFrames(restrictionsData);
        setIsEditing(undefined);
    };

    const toggleEditMode = (directToggleTo?: keyof UnitFormsEditing) => {
        if (!directToggleTo) {
            resetForm();
            setIsEditing(undefined);
            return;
        }

        if (
            unitInfoForm.formState.isDirty ||
            !isEqual(
                sortTimeFrames(selectedTimeFrames),
                sortTimeFrames(restrictionsData)
            )
        ) {
            showUnsavedChangesModal(() => {
                resetForm();
                setIsEditing({
                    [directToggleTo]: true,
                });
            });
        } else {
            setIsEditing({
                [directToggleTo]: true,
            });
        }
    };

    const formLoading = updateUnitIsLoading || unitByIdLoading;

    return (
        <Container>
            <SubHeader backArrow title={unitData.name} />
            <Plate padding="large" spaceBottom={true}>
                {unitReplacementWindowsData &&
                    unitReplacementWindowsData.length > 0 && (
                        <Alert
                            type="danger"
                            bleedHorizontal="xLarge"
                            bleedTop="xLarge"
                            style={{ marginBottom: "20px" }}>
                            Den här enheten har kommande ersättningsfönster
                        </Alert>
                    )}
                {formLoading ? (
                    <Loader />
                ) : (
                    <Grid gutter={space.padding}>
                        <Cell gutter={space.padding}>
                            <CellHeading>Information</CellHeading>
                            <CellBlock
                                active={isEditing?.information}
                                noOutlines={false}
                                style={{ marginBottom: "20px" }}
                                onEdit={() => toggleEditMode("information")}>
                                {isEditing?.information ? (
                                    <>
                                        <UnitInfoForm
                                            {...unitInfoForm}
                                            isNewForm={false}
                                            isSubmitLoading={formLoading}
                                            onCancel={() =>
                                                toggleEditMode(undefined)
                                            }
                                            onSubmit={(data) =>
                                                saveUnitDetails(
                                                    convertUnitForInput(data),
                                                    selectedTimeFrames
                                                )
                                            }
                                        />
                                        <TriggerFormInitially
                                            trigger={unitInfoForm.trigger}
                                        />
                                    </>
                                ) : (
                                    <UnitInfoListing {...unitInfoForm} />
                                )}
                            </CellBlock>
                            {unitReplacementWindowsData &&
                                unitReplacementWindowsData.length > 0 && (
                                    <>
                                        <CellHeading>
                                            {`Ersättningsfönster (${unitReplacementWindowsData.length})`}
                                        </CellHeading>
                                        {unitReplacementWindowsData.map(
                                            (item) => (
                                                <ReplacementTimeWindow
                                                    key={
                                                        item
                                                            .replacedHomeTimeWindow
                                                            .id
                                                    }
                                                    spaceBottom>
                                                    <ReplacedTimeWindowLabel>{`${
                                                        item.replacedHomeTimeWindow.timeSlotDay.split(
                                                            " "
                                                        )[0]
                                                    } ${
                                                        item
                                                            .replacedHomeTimeWindow
                                                            .timeSlotDayShort
                                                    } ${
                                                        item
                                                            .replacedHomeTimeWindow
                                                            .timeSlotTime
                                                    }`}</ReplacedTimeWindowLabel>
                                                    <ReplacementTimeWindowLabel>{`Ny tid: ${
                                                        item.replacementHomeTimeWindow.timeSlotDay.split(
                                                            " "
                                                        )[0]
                                                    } ${
                                                        item
                                                            .replacementHomeTimeWindow
                                                            .timeSlotDayShort
                                                    } ${
                                                        item
                                                            .replacementHomeTimeWindow
                                                            .timeSlotTime
                                                    }`}</ReplacementTimeWindowLabel>
                                                </ReplacementTimeWindow>
                                            )
                                        )}
                                    </>
                                )}
                        </Cell>
                        <Cell
                            gutter={space.padding}
                            extraPaddingLeft={undefined}>
                            <CellHeading>Tidsfönster</CellHeading>

                            {restrictionsData.length ||
                            isEditing?.timeFrames ? (
                                <CellBlock
                                    onEdit={() => toggleEditMode("timeFrames")}
                                    active={isEditing?.timeFrames}>
                                    {isEditing?.timeFrames ? (
                                        <>
                                            <CellBlockSection>
                                                <CellBlockTitle>
                                                    Ändra tidsfönster
                                                </CellBlockTitle>
                                                <CellBlockValue>
                                                    Välj vilka leveranstider som
                                                    ska vara tillgängliga för
                                                    enheten.
                                                </CellBlockValue>
                                            </CellBlockSection>
                                            <CellBlockSection>
                                                <TimeFrameList
                                                    data={availableTimeFrames}
                                                    overflowValue={space.medium}
                                                    setChoice={
                                                        updateSelectedTimeframes
                                                    }
                                                    selectedData={
                                                        selectedTimeFrames
                                                    }
                                                />
                                            </CellBlockSection>
                                            <CellBlockFooter
                                                isLoading={formLoading}
                                                onCancel={() =>
                                                    toggleEditMode(undefined)
                                                }
                                                onSubmit={() =>
                                                    saveUnitDetails(
                                                        convertUnitForInput(
                                                            unitInfoForm.getValues()
                                                        ),
                                                        selectedTimeFrames
                                                    )
                                                }
                                                hasChangesText={
                                                    !isEqual(
                                                        sortTimeFrames(
                                                            selectedTimeFrames
                                                        ),
                                                        sortTimeFrames(
                                                            restrictionsData
                                                        )
                                                    )
                                                        ? "Valet av tidsfönster har osparade ändringar."
                                                        : undefined
                                                }
                                                disableSubmit={
                                                    !unitInfoForm.formState
                                                        .isValid ||
                                                    isEqual(
                                                        sortTimeFrames(
                                                            restrictionsData
                                                        ),
                                                        sortTimeFrames(
                                                            selectedTimeFrames
                                                        )
                                                    )
                                                }
                                            />
                                        </>
                                    ) : (
                                        <UnitTimeFramesListing
                                            restrictions={restrictionsData}
                                        />
                                    )}
                                </CellBlock>
                            ) : (
                                <Button
                                    outsideTitle="Lägg till tidsfönster"
                                    shape="round"
                                    icon={assets.plus}
                                    onClick={() => toggleEditMode("timeFrames")}
                                />
                            )}
                        </Cell>
                    </Grid>
                )}
            </Plate>
        </Container>
    );
};

export default UnitDetailsView;
