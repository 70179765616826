import { DevTool } from "@hookform/devtools";
import React from "react";
import { Controller } from "react-hook-form";
import { getValidationResultFromFormError } from "../../common/validations";
import Checkbox from "../../components/Checkbox";
import { FormField, FormFieldSet } from "../../components/FormField";
import { CellBlockFooter } from "../../components/Grid/CellBlock";
import TextInput from "../../components/TextInput";
import {
    OrdererInfoFormData,
    useOrdererInfoForm,
} from "./OrdererDetailsView.hooks";

export interface OrdererInfoData {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    customerCreationAllowed: boolean;
}

interface Props extends ReturnType<typeof useOrdererInfoForm> {
    onCancel: () => void;
    onSubmit: (data: OrdererInfoFormData) => void;
    isNewForm?: boolean;
    isSubmitLoading?: boolean;
}

const OrdererInfoForm = (props: Props) => {
    return (
        <form onSubmit={props.handleSubmit(props.onSubmit)}>
            <FormFieldSet>
                <FormField halfSize>
                    <Controller
                        name="firstName"
                        control={props.control}
                        rules={{ required: true }}
                        render={({
                            field: { onBlur, onChange, value },
                            fieldState,
                        }) => (
                            <TextInput
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                required
                                autoComplete="on"
                                placeholder="Förnamn"
                                validation={getValidationResultFromFormError(
                                    fieldState
                                )}
                            />
                        )}
                    />
                </FormField>
                <FormField halfSize>
                    <Controller
                        name="lastName"
                        control={props.control}
                        rules={{ required: true }}
                        render={({
                            field: { onBlur, onChange, value },
                            fieldState,
                        }) => (
                            <TextInput
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                required
                                autoComplete="on"
                                placeholder="Efternamn"
                                validation={getValidationResultFromFormError(
                                    fieldState
                                )}
                            />
                        )}
                    />
                </FormField>
            </FormFieldSet>
            <FormFieldSet>
                <FormField halfSize>
                    <Controller
                        name="phone"
                        control={props.control}
                        rules={{ required: true }}
                        render={({
                            field: { onBlur, onChange, value },
                            fieldState,
                        }) => (
                            <TextInput
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                autoComplete="on"
                                required
                                placeholder="Telefon"
                                validation={getValidationResultFromFormError(
                                    fieldState
                                )}
                            />
                        )}
                    />
                </FormField>
                <FormField halfSize>
                    <Controller
                        name="email"
                        control={props.control}
                        rules={{ required: true }}
                        render={({
                            field: { onBlur, onChange, value },
                            fieldState,
                        }) => (
                            <TextInput
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                                autoComplete="on"
                                required
                                placeholder="E-post"
                                validation={getValidationResultFromFormError(
                                    fieldState
                                )}
                            />
                        )}
                    />
                </FormField>
            </FormFieldSet>
            <FormField noMargin>
                <Controller
                    name="customerCreationAllowed"
                    control={props.control}
                    render={({ field: { onChange, value } }) => (
                        <Checkbox
                            onChange={onChange}
                            value={value}
                            text="Får lägga till nya slutkunder"
                        />
                    )}
                />
            </FormField>
            <DevTool control={props.control} />
            <CellBlockFooter
                disableSubmit={
                    !props.formState.isValid || !props.formState.isDirty
                }
                hasChangesText={
                    !props.isNewForm && props.formState.isDirty
                        ? "Förmuläret har osparade ändringar."
                        : undefined
                }
                marginTop
                isLoading={props.isSubmitLoading}
                onCancel={props.onCancel}
                submitButtonType="submit"
            />
        </form>
    );
};

export default OrdererInfoForm;
