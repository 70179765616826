import React, { useState } from "react";
import styled from "styled-components";
import { space } from "../../common/variables";
import Search from "../../components/Search/Search";

const CustomerSearch = ({
    initialValue,
    onEnterPress,
}: {
    initialValue?: string;
    onEnterPress: (value: string) => void;
}) => {
    const [input, setInput] = useState(initialValue || "");

    return (
        <Wrapper>
            <Search
                size="large"
                inputPlaceholder="Sök slutkunder (förnamn, efternamn eller enhet)"
                inputText={input}
                setInputText={setInput}
                onReset={() => {
                    setInput("");
                    onEnterPress("");
                }}
                onEnterPress={() => onEnterPress(input)}
                useDropdown={false}
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    margin-bottom: ${space.large};
`;

export default CustomerSearch;
