import React, { useContext } from "react";
import Button from "../../components/Button";
import { ModalContext } from "../../components/Modal";

export const useCustomerDeletionConfirmModal = () => {
    const { setModalOptions } = useContext(ModalContext);

    const showCustomerDeletionConfirmModal = (
        firstName: string,
        lastName: string,
        deleteCustomer: () => void
    ) => {
        setModalOptions({
            visible: true,
            title: `Ta bort slutkund?`,
            subtitle: `Är du säker på att du vill ta bort ${firstName} ${lastName}?`,
            buttons: [
                <Button
                    size="small"
                    title="Ta bort"
                    theme="red"
                    onClick={() => deleteCustomer()}
                />,
                <Button
                    size="small"
                    title="Avbryt"
                    theme="inverted"
                    onClick={() => setModalOptions({ visible: false })}
                />,
            ],
        });
    };

    return showCustomerDeletionConfirmModal;
};
