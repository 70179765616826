export const navigationItems = [
    { title: "Slutkund", path: "/slutkund" } as const,
    { title: "Beställare", path: "/bestallare" } as const,
    { title: "Enheter", path: "/enheter" } as const,
] as const;

export const navigationItemRoutes = {
    customerById: (id: string) => `${navigationItems[0].path}/${id}`,
    units: () => navigationItems[2],
    unitById: (id: string) => `${navigationItems[2].path}/${id}`,
};

export type NavigationItem = (typeof navigationItems)[number];
